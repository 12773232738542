.project-wrapper {
  min-height: 100vh;
  width: 100vw;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  margin: auto;

  .project__landing-cont {
    width: 100%;
    height: auto;
    // border: 1px solid;
    background-color: black;
    color: white;
    .project__landing-cont__inner-box {
      // border: 1px solid ;
      width: 70%;
      height: 100%;
      margin-left: 15%;
      display: flex;
      flex-direction: column;
      max-width: 520px;
      .project__landing-cont__inner-box__bold-text {
        padding-top: 15%;
        font-size: 40px;
      }
      .project__landing-cont__inner-box__light-text {
        padding-top: 12px;
        font-size: 16px;
        line-height: 1.6;
        padding-bottom: 17%;
      }
    }
  }

  .project__pic-1 {
    width: 100%;
    height: auto;
    background-color: #cacaca;
    box-shadow: 0 1.7px 1.1px -10px rgba(19, 10, 43, 0.9),
      0 4.3px 3px -10px rgba(19, 10, 43, 0.13),
      0 8.9px 6.6px -10px rgba(19, 10, 43, 0.17),
      0 11px 17px -10px rgba(19, 10, 43, 0.3);
    justify-content: center;
    display: flex;
    img {
      width: 80%;
    }
  }
  .project__pic-2 {
    box-shadow: 0 1.7px 1.1px -10px rgba(19, 10, 43, 0.9),
      0 4.3px 3px -10px rgba(19, 10, 43, 0.13),
      0 8.9px 6.6px -10px rgba(19, 10, 43, 0.17),
      0 11px 17px -10px rgba(19, 10, 43, 0.3);
    width: 70%;
    height: auto;
    background-color: #dddddd;
    margin: auto;
    margin-bottom: 25px;
    img {
      width: 100%;
    }
  }

  .project__box-1-cont {
    padding-top: -20px;
    padding-bottom: 100px;
    width: 70%;
    // border: 1px solid;
    display: flex;
    margin: auto;
    justify-content: space-between;
    .project__box-1-cont__left-cont {
      // border: 1px solid;
      width: 48%;
    }
    .project__box-1-cont__right-cont {
      // border: 1px solid;
      width: 60%;
    }
    .project__box-1-cont__textbox-1 {
      color: #747474;
      line-height: 1.6;
    }
    .project__box-1-cont__titlebox-1 {
      padding-top: 16%;
      color: #1c1d1e;
      font-size: 28px;
      padding-bottom: 20px;
      margin: auto;
    }
    .project__box-1-cont__titlebox-2 {
      padding-top: 350px;
      color: #1c1d1e;
      font-size: 28px;
      padding-bottom: 20px;
    }
    .project__box-1-cont__picture-1 {
      margin-top: 70px;
      height: 460px;
      width: 100%;
      background-color: #dddddd;
    }
    .project__box-1-cont__picture-2 {
      margin-top: 190px;
      height: 460px;
      width: 100%;
      background-color: #dddddd;
    }
  }
}
@media screen and (max-width: 1025px) {
  #mobile-project__pic-1 {
    img {
      width: 90%;
    }
  }
  #mobile-project__box-1-cont {
    width: 92%;
    justify-content: center;
    text-align: center;
  }
  #mobile-project__pic-2 {
    width: 92%;
  }
}
@media screen and (max-width: 892px) {
  #mobile-project__box-1-cont {
    .project__box-1-cont__right-cont {
      width: 70%;
    }
  }
}
@media screen and (max-width: 620px) {
  #mobile-project__landing-cont {
    .project__landing-cont__inner-box {
      .project__landing-cont__inner-box__bold-text {
        font-size: 32px;
      }
    }
  }
  #mobile-project__pic-1 {
    img {
      width: 97%;
    }
  }
  #mobile-project__box-1-cont {
    width: 98%;
    justify-content: center;
    text-align: center;
  }
  #mobile-project__pic-2 {
    width: 92%;
  }
}
@media screen and (max-width: 525px) {
  #mobile-project__box-1-cont {
    .project__box-1-cont__right-cont {
      width: 100%;
    }
  }
}
@media screen and (max-width: 414px) {
  #mobile-project__landing-cont {
    .project__landing-cont__inner-box {
      .project__landing-cont__inner-box__bold-text {
        font-size: 26px;
      }
      .project__landing-cont__inner-box__light-text {
        font-size: 14px;
      }
    }
  }
  #mobile-project__box-1-cont {
    .project__box-1-cont__right-cont {
      padding-left: 1%;
      padding-right: 1%;
      width: 97%;
      .project__box-1-cont__titlebox-1 {
        font-size: 24px;
      }
      .project__box-1-cont__textbox-1 {
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  #mobile-project__landing-cont {
    .project__landing-cont__inner-box {
      .project__landing-cont__inner-box__bold-text {
        font-size: 20px;
      }
      .project__landing-cont__inner-box__light-text {
        font-size: 12px;
      }
    }
  }
  #mobile-project__box-1-cont {
    .project__box-1-cont__right-cont {
      font-size: 23px;
      width: 96%;
      .project__box-1-cont__titlebox-1 {
        font-size: 22px;
      }
      .project__box-1-cont__textbox-1 {
        font-size: 13px;
      }
    }
  }
}
@media screen and (max-width: 280px) {
  #mobile-project__landing-cont {
    .project__landing-cont__inner-box {
      .project__landing-cont__inner-box__bold-text {
        font-size: 18px;
      }
      .project__landing-cont__inner-box__light-text {
        font-size: 11px;
      }
    }
  }
  #mobile-project__box-1-cont {
    .project__box-1-cont__right-cont {
      .project__box-1-cont__textbox-1 {
        font-size: 11px;
      }
    }
  }
}
