.content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.test-background {
  width: 100vw;
  height: 156vh;
  overflow-y: auto;
  position: relative;
  scroll-behavior: smooth;
  margin-right: -100vw;
  background-size: cover;
  &--REMODELING {
    background-image: url("https://pinergyimages.s3-us-west-1.amazonaws.com/constructionRemodeling.jpg");
  }
  &--CONSTRUCTION {
    background-image: url("https://pinergyimages.s3-us-west-1.amazonaws.com/constructionBuilding.jpg");
  }
  &--SPECIALITY {
    background-image: url("https://pinergyimages.s3-us-west-1.amazonaws.com/constructionSpark.jpg");
  }
}
.test-content-wrapper {
  z-index: 2;
  position: sticky;
  top: 0;

  // bottom: 0;
  height: fit-content;
  .test-content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 94vh;
    z-index: 2;
    .test-content--top {
      display: flex;
      width: 100vw;
      height: 100%;
      justify-content: flex-start;
      & > div {
        width: 100%;
        height: 100%;
        background-color: white;
      }
    }

    &--1 {
      .test-content--top {
        flex-direction: row;
        & > div {
          background: linear-gradient(
            17deg,
            #fff 0%,
            #fff 53%,
            transparent 50%,
            transparent 100%
          );
          &.normal {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 14%;
            @media screen and (max-width: 1555px) {
              padding-top: 16%;
              .service-content--container-2 {
                width: 100%;
              }
            }
            @media screen and (max-width: 1321px) {
              padding-top: 23%;
            }
            @media screen and (max-width: 1110px) {
              padding-top: 30%;
            }
            @media screen and (max-width: 1110px) {
              padding-top: 30%;
            }
            @media screen and (max-width: 933px) {
              padding-top: 44%;
            }
            @media screen and (max-width: 830px) {
              padding-top: 47%;
            }
            @media screen and (max-width: 725px) {
              padding-top: 55%;
            }
            @media screen and (max-width: 650px) {
              padding-top: 65%;
            }
            @media screen and (max-width: 562px) {
              padding-top: 78%;
            }
            @media screen and (min-height: 1024px) and (min-width: 1520px) {
              padding-top: 11%;
            }

            .service-content--container-1 {
              @media screen and (max-width: 1321px) {
                width: 52%;
              }
              @media screen and (max-width: 955px) {
                width: 66%;
              }
              @media screen and (max-width: 605px) {
                width: 95%;
              }
            }
            .service-content--container-2 {
              margin-top: 5%;
            }
          }
          &.reversed {
            background: linear-gradient(
              197deg,
              #fff 0%,
              #fff 53%,
              transparent 50%,
              transparent 100%
            );
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            @media screen and (max-width: 1555px) {
              padding-bottom: 4%;
            }
            @media screen and (max-width: 1225px) {
              padding-bottom: 18%;
            }
            .service-content--container-1 {
              align-self: flex-end;
              margin-top: 2%;
              margin-bottom: 13%;
              text-align: end;
              margin-right: 5%;
              @media screen and (max-width: 971px) {
                width: 45%;
              }
              @media screen and (max-width: 695px) {
                width: 61%;
              }
              @media screen and (max-width: 565px) {
                width: 63%;
              }
            }
            .service-content--container-2 {
              align-self: center;
              margin-left: 1%;
              margin-top: 2%;
              @media screen and (min-height: 1024px) and (max-width: 1520px) {
                margin-top: 6%;
              }
              @media screen and (min-height: 1366px) {
                margin-top: 17%;
              }
              .svg__container {
                .svg__image {
                  height: 53%;
                }
              }
            }
          }
          .service-content--container-1 {
            // margin-top: 16%;
            margin-left: 2%;
            width: 32%;
            h4 {
              margin-top: 4%;
              // padding-left: 1%;
              margin-bottom: 6%;
            }
            span {
              line-height: 1.6;
            }
          }
          .service-content--container-2 {
            justify-content: space-around;
            display: flex;
            width: 70%;
            @media screen and (max-width: 1555px) {
              width: 100%;
            }
            .svg__container {
              text-align: center;
              .svg__image {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
    &--2 {
      .test-content--top {
        flex-direction: row-reverse;
      }
    }
    &--3 {
      .test-content--top {
        & > div {
          width: 100%;
        }
      }
    }
    .test-content--bottom {
      width: 100%;
      height: 30%;
      background-color: white;
    }
  }
}

@media screen and (max-width: 540px) and (max-height: 736px) {
  #mobile-services-content--wrapper {
    &.reversed {
      padding-top: 5%;
      .service-content--container-1 {
        margin-top: -4%;
      }
      .service-content--container-2 {
        margin-top: 0;
        .svg__container {
          .svg__title {
            margin-top: -19%;
          }
        }
      }
    }
    &.normal {
      padding-top: 49%;
    }

    .service-content--container-1,
    .service-content--container-2 {
      h1 {
        font-size: 1.5rem;
      }
      h4 {
        font-size: 1rem;
        margin-top: 3%;
        margin-bottom: 3%;
      }
      span {
        line-height: 1;
        font-size: 0.8rem;
      }
      .svg__container {
        .svg__image {
          height: 53%;
          .service-card-svg-outer {
            height: 80%;
          }
        }
        .svg__title {
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  #mobile-services-content--wrapper {
    &.normal {
      padding-top: 78%;
      .service-content--container-2 {
        .svg__container {
          .svg__title {
            margin-top: -5%;
          }
        }
      }
    }
  }
  #mobile-services-content--wrapper {
    .service-content--container-1,
    .service-content--container-2 {
      h1 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.2rem;
        margin-top: 3%;
        margin-bottom: 3%;
      }
      span {
        font-size: 0.7rem;
      }
      .svg__container {
        .svg__image {
          height: 53%;
          .service-card-svg-outer {
            height: 80%;
          }
        }
        .svg__title {
          font-size: 0.7rem;
        }
      }
    }
  }
}
@media screen and (max-width: 411px) and (min-height: 812px) {
  #mobile-services-content--wrapper {
    &.normal {
      padding-top: 95%;
    }
    &.reversed {
      .service-content--container-1 {
        width: 77%;
        margin-top: -5%;
      }
      .service-content--container-2 {
        margin-top: 12%;
        .svg__container {
          .svg__title {
            margin-top: -20%;
          }
        }
      }
    }
    .service-content--container-1,
    .service-content--container-2 {
      h1 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.2rem;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  #mobile-services-content--wrapper {
    &.reversed {
      .service-content--container-1 {
        width: 70%;
        margin-top: -10%;
      }
    }
    .service-content--container-1,
    .service-content--container-2 {
      h1 {
        font-size: 1.5rem;
      }
      h4 {
        font-size: 1rem;
        margin-top: 3%;
        margin-bottom: 3%;
      }
      span {
        font-size: 0.7rem;
      }
      .svg__container {
        .svg__title {
          font-size: 0.7rem;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  #mobile-services-content--wrapper {
    &.reversed {
      padding-top: 0;
      .service-content--container-1 {
        margin-top: 3%;
      }
      .service-content--container-2 {
        .svg__container {
          height: 93px;
          .svg__image {
            height: 53%;
            .service-card-svg-outer {
              height: 67%;
            }
          }
          .svg__title {
            margin-top: -16%;
          }
        }
      }
    }
    &.normal {
      padding-top: 77%;
      .service-content--container-2 {
        .svg__container {
          height: 93px;
          .svg__image {
            .service-card-svg-outer {
              height: 67%;
            }
          }
          .svg__title {
            margin-top: -12%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  #mobile-services-content--wrapper {
    &.reversed {
      .service-content--container-1 {
        width: 83%;
        margin-top: 1%;
      }
    }
    .service-content--container-1 {
      h1 {
        font-size: 1rem;
      }
      h4 {
        font-size: 0.7rem;
      }
      span {
        font-size: 0.6rem;
      }
    }
  }
}
@media screen and (max-width: 280px) {
  #mobile-services-content--wrapper {
    &.normal {
      padding-top: 88%;
    }
    .service-content--container-1 {
      margin-top: 0;
    }
    .service-content--container-2 {
      margin-top: 7%;
      .svg__container {
        .svg__title {
          font-size: 0.6rem;
        }
      }
    }
  }
}
