
body {
  margin: 0;
  font-family: Lucida Sans, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app__wrapper {
  // min-height: calc(100vh - 70px);
  min-height: 100vh;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  margin-top:70px;
  overflow-x: hidden;
}

.app__content__wrapper {
  min-height: calc(100vh - 70px);
  width: 100%;
  height: auto;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;

  &::-webkit-scrollbar {
    display: none;
  }
  .app__content__wrapper__container {
    max-width: 1440px;
    width: 100vw;
    min-height: calc(100vh - 70px);
  }
}

