.Contact-us__wrapper {
  // min-height: calc(100vh - 70px);
  min-height: 100vh;
  width: 100vw;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  .Contact-us__form__wrapper {
    height: 650px;
    width: 100%;
    margin-top: 6%;
    // background-color: ;
    // border: 1px solid;
    display: flex;
    .Contact-us__form__wrapper__fillout-box {
      width: 50%;
      // border: 1px solid;
      background-color: #f4f4f4;
      min-width: 450px;
      .Contact-us__form__wrapper__fillout-box__textbox {
        height: 100%;
        width: 63%;
        // border: 1px solid;
        padding-top: 80px;
        padding-left: 26%;
        .Contact-us__form__wrapper__fillout-box__textbox__bold-text {
          width: 100%;
          // border: 1px solid;
          font-size: 40px;
          font-weight: 400;
          font-size: 31px;
          line-height: 1.25;
        }
        .Contact-us__form__wrapper__fillout-box__textbox__light-text {
          width: 100%;
          margin-top: 20px;
          margin-bottom: 20px;
          // border: 1px solid;
          font-size: 40px;
          font-weight: 500;
          font-size: 15px;
          line-height: 1.25;
          color: #747474;
        }
        .Contact-us__form__wrapper__fillout-box__textbox__typing-1 {
          border: none;
          background-color: #f4f4f4;
          margin-top: 25px;
          width: 100%;
          height: 40px;
          // border-bottom: 1px solid #1c1d1e;
          color: #a8a8a8;
          outline: none;
        }
        .Contact-us__form__wrapper__fillout-box__textbox__typing-2 {
          border: none;
          background-color: #f4f4f4;
          margin-top: 30px;
          width: 100%;
          border-bottom: 1px solid #1c1d1e;
          color: #a8a8a8;
          height: 110px;
          padding: 0;
          font-size: 13.3333px;
          font-weight: 400;
          font-family: Arial;
          outline: none;
          resize: none;
          overflow-y: scroll;
        }
        .Contact-us__form__wrapper__fillout-box__textbox__Submit-button {
          margin-top: 20px;
          background-color: black;
          color: white;
          padding: 10px;
          width: 100px;
          border-radius: 30px;
          outline: none;
          &:disabled:hover {
            cursor: default;
            background-color: black;
            color: white;
          }
          &:hover {
            background-color: white;
            color: black;
            cursor: pointer;
          }
        }
      }
    }
    .Contact-us__form__wrapper__picture-box {
      width: 50%;
      // border: 1px solid;
      background-color: #dddddd;
      text-align: center;
    }
  }

  .Contact-us__card-block__wrapper {
    // height: 600px;
    width: 100%;
    margin-bottom: 100px;
    // border: 1px solid;
    .Contact-us__card-block__wrapper__innerwrap {
      // height: 450px;
      width: 67%;
      margin: auto;
      // border: 1px solid;
      margin-top: 75px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .Contact-us__card-block__wrapper__innerwrap__block {
        box-shadow: 5px 5px 5px grey;
        width: 260px;
        height: 450px;
        margin: 15px;
        // border: 1px solid;
        .Contact-us__card-block__wrapper__innerwrap__block__pic {
          width: 100%;
          height: 50%;
          // border: 1px solid;
          background-color: #dddddd;
        }
        .Contact-us__card-block__wrapper__innerwrap__block__info {
          width: 100%;
          height: 50%;
          // border: 1px solid;
          background-color: #f4f4f4;

          .Contact-us__card-block__wrapper__innerwrap__block__info__svg__Address {
            padding: 83px 0px 0px 10px;
          }
          .Contact-us__card-block__wrapper__innerwrap__block__info__svg__Email {
            padding: 30px 0px 0px 10px;
          }
          .Contact-us__card-block__wrapper__innerwrap__block__info__svg__Phone {
            padding: 0px 0px 0px 8px;
          }

          .Contact-us__card-block__wrapper__innerwrap__block__info__bold-text {
            width: 100%;
            padding: 30px 0px 0px 30px;
            font-size: 20px;
            // border: 1px solid;
          }

          .Contact-us__card-block__wrapper__innerwrap__block__info__light-text {
            width: 100%;
            padding: 30px 30px 0px 5px;
            font-size: 13px;
            color: #747474;
            // border: 1px solid;
            display: flex;
          }
          .Contact-us__card-block__wrapper__innerwrap__block__info__phone {
            width: 100%;
            padding-top: 5px;
            font-size: 13px;
            color: #747474;
            display: flex;
            overflow: visible;
          }
          .Contact-us__card-block__wrapper__innerwrap__block__info__space {
            width: 8px;
          }
        }
      }
    }
  }
}

.borderLine {
  border-bottom: 1px solid #0a509c;
  animation: borderLine 0.5s linear forwards;
}
@keyframes borderLine {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  #mobile-Contact-us__form__wrapper__fillout-box {
    .Contact-us__form__wrapper__fillout-box__textbox {
      width: 73%;
      padding-left: 23%;
    }
  }
}

@media screen and (max-width: 865px) {
  .Contact-us__form__wrapper__fillout-box__textbox__typing-2 {
    resize: none;
  }
  .Contact-us__form__wrapper {
    .Contact-us__form__wrapper__picture-box {
      display: none;
    }
    #mobile-Contact-us__form__wrapper__fillout-box {
      width: 100%;
    }
  }
}

@media screen and (max-width: 375px) {
  #mobile-Contact-us__form__wrapper__fillout-box {
    .Contact-us__form__wrapper__fillout-box__textbox {
      padding-left: 16%;
    }
  }
}
@media screen and (max-width: 360px) {
  #mobile-Contact-us__form__wrapper__fillout-box {
    .Contact-us__form__wrapper__fillout-box__textbox {
      padding-left: 2%;
      padding-right: 3%;
    }
  }
}
@media screen and (max-width: 280px) {
  #mobile-Contact-us__form__wrapper__fillout-box {
    .Contact-us__form__wrapper__fillout-box__textbox {
      padding-right: 39%;
      padding-left: 1%;
      width: 100%;
    }
  }
}
