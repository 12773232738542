.projects-wrapper {
  min-height: 100vh;
  width: 100vw;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  margin: auto;
  .projects__filter--cont {
    display: flex;
    justify-content: center;
    width: 100%;
    align-self: center;
    margin-top: 3%;
    .projects__button__cont {
      width: 150px;
      margin: 0 0.5%;
      border: none;
      padding-left: 1%;
      padding-right: 1%;
      button {
        background: none;
        width: 100%;
        font: inherit;
        cursor: pointer;
        outline: none;
        border: 1px solid black;
        border-radius: 10px;
        padding-top: 3%;
        padding-bottom: 3%;
      }
    }
  }
  .projects__landing-cont {
    width: 100%;
    height: 350px;
    // border: 1px solid;
    background-color: black;
    color: white;
    .projects__landing-cont__inner-box {
      // border: 1px solid ;
      width: 80%;
      height: 100%;
      margin-left: 15%;
      display: flex;
      flex-direction: column;
      max-width: 490px;
      .projects__landing-cont__inner-box__bold-text {
        padding-top: 99px;
        font-size: 40px;
        font-family: "Poppins";
      }
      .projects__landing-cont__inner-box__light-text {
        padding-top: 12px;
        font-size: 16px;
        line-height: 1.6;
        font-family: "Lato";
      }
    }
  }

  .projects__card-cont {
    padding-top: 2%;
    padding-bottom: 90px;

    .projects__card-cont__innerbox {
      width: 70%;
      // border: 1px solid;
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .projects__card-cont__innerbox__card {
        box-shadow: 0 1.7px 1.1px -10px rgba(19, 10, 43, 0.9),
          0 4.3px 3px -10px rgba(19, 10, 43, 0.13),
          0 8.9px 6.6px -10px rgba(19, 10, 43, 0.17),
          0 11px 17px -10px rgba(19, 10, 43, 0.3);
        border-radius: 6px;
        margin: 15px;
        width: 290px;
        height: 410px;
        border: 1px solid lightgray;
        display: flex;
        flex-direction: column;
        // border-radius: 10px;
        overflow: hidden;
        &:hover {
          cursor: pointer;
        }
        .projects__card-cont__innerbox__card__pic {
          height: 250px;
          width: 100%;
          // border: 1px solid;
          background-color: #dddddd;
          overflow: hidden;
          .projects__card-cont__innerbox__card__over-lay {
            width: 70%;
            height: 70%;
            margin: auto;
            margin-top: 40px;
            background-color: rgba(255, 255, 255, 0.3);
            text-align: center;
            padding-top: 70px;
          }
        }
        .projects__card-cont__innerbox__card__info {
          height: 160px;
          width: 100%;
          // border: 1px solid;
          background-color: #f4f4f4;
          .projects__card-cont__innerbox__card__info__title {
            color: #a8a8a8;
            padding: 40px 30px 0px 30px;
            font-size: 11px;
            font-weight: 500;
          }
          .projects__card-cont__innerbox__card__info__text {
            padding: 15px 30px 0px 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }

  .projects__view-all-button {
    margin: auto;
    padding: 15px;
    background-color: black;
    color: white;
    width: 190px;
    border-radius: 40px;
    text-align: center;
    margin-bottom: 100px;
    border: 1px solid;
    &:hover {
      background-color: white;
      color: black;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 914px) {
  #mobile-projects__card-cont__innerbox {
    justify-content: center;
  }
}

@media screen and (max-width: 540px) {
  #mobile-projects__filter__cont {
    width: 88%;
  }
}

@media screen and (max-width: 540px) {
  #mobile-projects__filter__cont {
    width: 96%;
    .projects__button__cont {
      width: 90px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 360px) {
  #mobile-projects__filter__cont {
    width: 100%;
    .projects__button__cont {
      width: 72px;
      font-size: 9px;
      button {
        padding-top: 5%;
        padding-bottom: 5%;
      }
    }
  }
  #mobile-projects__card-cont__innerbox {
    width: 100%;
  }
}

@media screen and (max-width: 280px) {
  #mobile-projects__filter__cont {
    .projects__button__cont {
      width: 67px;
      font-size: 8.2px;
    }
  }
}
