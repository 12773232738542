.projects__carousel {
  display: block;
  text-align: left;
  position: relative;
  margin-bottom: 22px;
  background-color: #f4f4f4;
  &.reversed {
    display: flex;
    flex-wrap: wrap;
    .carousel__slide__figCaption {
      width: 25%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-self: center;
      flex: 0 0 auto;
      width: 25%;
      min-width: 150px;
      .carousel__slide__title{
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: rgba(0, 0, 0, 0.5);
        display: block;
        font-size: 24px;
      }
      a {
        button {
          margin-top: 8%;
        }
      }
    }
    .carousel__slides {
      width: 75%;
    }
    .carousel__thumbnails {
      width: 100%;
      margin-left: 0%;
    }
  }
  > input {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:nth-of-type(6):checked
      ~ .carousel__slides
      .carousel__slide:first-of-type {
      margin-left: -500%;
    }
    &:nth-of-type(5):checked
      ~ .carousel__slides
      .carousel__slide:first-of-type {
      margin-left: -400%;
    }
    &:nth-of-type(4):checked
      ~ .carousel__slides
      .carousel__slide:first-of-type {
      margin-left: -300%;
    }
    &:nth-of-type(3):checked
      ~ .carousel__slides
      .carousel__slide:first-of-type {
      margin-left: -200%;
    }
    &:nth-of-type(2):checked
      ~ .carousel__slides
      .carousel__slide:first-of-type {
      margin-left: -100%;
    }
    &:nth-of-type(1):checked
      ~ .carousel__slides
      .carousel__slide:first-of-type {
      margin-left: 0%;
    }

    &:nth-of-type(1):checked ~ .carousel__thumbnails li:nth-of-type(1) {
      box-shadow: 0px 0px 0px 5px rgba(5, 5, 5, 1);
    }
    &:nth-of-type(2):checked ~ .carousel__thumbnails li:nth-of-type(2) {
      box-shadow: 0px 0px 0px 5px rgba(5, 5, 5, 1);
    }
    &:nth-of-type(3):checked ~ .carousel__thumbnails li:nth-of-type(3) {
      box-shadow: 0px 0px 0px 5px rgba(5, 5, 5, 1);
    }
    &:nth-of-type(4):checked ~ .carousel__thumbnails li:nth-of-type(4) {
      box-shadow: 0px 0px 0px 5px rgba(5, 5, 5, 1);
    }
    &:nth-of-type(5):checked ~ .carousel__thumbnails li:nth-of-type(5) {
      box-shadow: 0px 0px 0px 5px rgba(5, 5, 5, 1);
    }
    &:nth-of-type(6):checked ~ .carousel__thumbnails li:nth-of-type(6) {
      box-shadow: 0px 0px 0px 5px rgba(5, 5, 5, 1);
    }
  }
}
@media screen and (max-width: 630px) {
    #mobile-projects__carousel--reversed{
        .carousel__slide__figCaption{
            width:100%;
            .carousel__slide__title{
                margin-bottom:1%;
            }
            a{
                button{
                    margin-top:2%;
                    margin-bottom:2%
                }
            }
        }
        .carousel__slides{
            width:100%;
        }
    }
}