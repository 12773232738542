.midContent__textCard{
  border-radius: 10px;
  background-color: #f4f4f4;
  display:flex;
  width:75%;
  height: 20%;
  justify-content: center;
  &.left{
      align-self: flex-end;
  }
  &.right{
    align-self: flex-end;
  }
 
  .midContent__textCard__title{
    font-weight: bold;
    line-height: 1.75;
    align-self:center;
    font-size: 14px;
    width: 17%;
  }
  .midContent__textCard__text{
    line-height: 1.75;
    color: #747474;
    align-self:center;
    width: 75%
  }
}
.midContent__textCard:first-child {
    margin-top:10px;
}
.midContent__textCard:last-child {
  margin-bottom:10px;
}

@media screen and (max-width: 1199px) {
  .midContent__textCard {
    flex-direction:column;
    .midContent__textCard__title{
      width: 100%;
      text-align: center;
    }
    .midContent__textCard__text{
      text-align:center;
    }
  }
}