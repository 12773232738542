.svg__container {
    width:22%;
    display:flex;
    flex-direction:column;
    height:148px;
    align-items: center;
    text-align-last: center;
    .svg__image {
        height:100px;
        width:100%
    }
    .svg__title{
        height:auto;
        width:100%
    }
}