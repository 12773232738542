// * {
//   border: 1px solid white;
// }
.footer__wrapper {
  width: 100%;
  height: 250px;
  background-color: #1c1d1e;
  display: flex;
  justify-content: flex-start;
  .footer__left__container {
    width: 42%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 229px;

    .footer__logo__container {
      padding-top: 22px;

      .footer__logo {
        object-fit: contain;
        width: 103px;
        height: 106px;
      }
    }

    .footer__email__container {
      display: flex;
      background-color: #f4f4f4;
      border-radius: 30px;
      .footer__email__text {
        height: 100%;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        width: 192px;
        padding-left: 11px;
        // font: inherit;
        // cursor: pointer;
        outline: inherit;
      }
      .footer__email__button {
        border-radius: 30px;
        background-color: #ffda00;
        width: 100px;
        height: 40px;
        padding: 4px 7px;
        border: none;
        text-decoration: none;
        font-size: 14px;
      }
    }

    .footer__text__container {
      justify-self: flex-end;
      color: #a8a8a8;
      line-height: 2;
      letter-spacing: 0.64px;
    }
  }

  .footer__right__container {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer__links__container {
      width: 16%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 23%;
      a {
        width: 100%;
        text-decoration: none;
        color: #ffffff;
      }
    }
    .footer__hiddenText__container {
      display: none;
    }
    .footer__hiddenEmail__container {
      display: none;
      background-color: #f4f4f4;
      border-radius: 30px;
      .footer__email__text {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        width: 192px;
        padding-left: 11px;
        // font: inherit;
        // cursor: pointer;
        outline: inherit;
      }
      .footer__email__button {
        border-radius: 30px;
        background-color: #ffda00;
        width: 100px;
        height: 40px;
        padding: 4px 7px;
        border: none;
        text-decoration: none;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  #mobile-footer__left__container {
    padding-left: 7px;
  }

  #mobile-footer__right__container {
    width: 54%;
    .footer__links__container {
      width: 21%;
    }
  }
}
@media screen and (max-width: 991px) {
  #mobile-footer__right__container {
    width: 57%;
  }
}
@media screen and (max-width: 780px) {
  #mobile-footer__wrapper {
    flex-direction: column;
    height: auto;
    .footer__left__container {
      width: 100%;
      padding-left: 0;
      .footer__logo__container {
        padding-top: 10px;
        padding-bottom: 1%;
      }
      .footer__email__container {
        display: none;
      }
      .footer__text__container {
        display: none;
      }
    }
    .footer__right__container {
      width: 100%;
      flex-direction: column;
      text-align: center;
      .footer__hiddenEmail__container {
        display: flex;
        input {
          align-self: center;
        }
      }
      .footer__hiddenText__container {
        display: flex;
        color: #a8a8a8;
        line-height: 2;
        letter-spacing: 0.64px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  #mobile-footer__left__container {
    .footer__text__container {
      letter-spacing: 0;
    }
  }
  #mobile-footer__right__container {
    .footer__links__container {
      font-size: 11px;
    }
  }
}
@media screen and (max-width: 280px) {
  #mobile-footer__wrapper {
    .footer__left__container {
      padding-left: 0px;
      .footer__text__container {
        font-size: 14px;
      }
    }
    .footer__right__container {
      .footer__hiddenEmail__container {
        width: 97%;
      }
      .footer__hiddenText__container {
        letter-spacing: -0.7px;
      }
    }
  }
}
