.services-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .services-container {
    height: 93vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    flex-direction: column;
    align-items: center;
    display: flex;
   &::-webkit-scrollbar {
      display: auto;
    }
    .services__bottom__container {
      width: 100%;
      display: flex;
      margin-bottom: 2%;
      max-width: 1440px;
      .bottom__img__container {
        width: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .textCard__text__container {
        width: 50%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        padding-left: 5%;
        padding-right: 10%;
        // padding-top: 54px;
        // padding-bottom: 54px;
        box-sizing: border-box;
        justify-content: center;

        .textCard__button__container {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 54px;
          margin-top: 27px;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  #mobile-services__bottom__container {
    .bottom__img__container {
      width: 60%;
    }
    .textCard__text__container {
      width: 40%;
      padding-left: 3%;
      padding-right: 6%;
    }
  }
}

@media screen and (max-width: 916px) {
  #mobile-services__bottom__container {
    flex-direction: column;
    .bottom__img__container {
      width: 100%;
    }
    .textCard__text__container {
      width: 100%;
    }
  }
}
