
.aboutUs-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;

  .aboutUs__topContent__container {
    margin-top: 100px;
    width: 80%;
    display: flex;
    margin-bottom: 60px;
    // height: 1000px;
    .topContent__container--left {
      margin-top: 100px;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .text__botImg__container {
        width: 93%;
        display: flex;
        justify-content: space-between;
        .botImg__hidden--left{
          display:none;
          width: 45%;
          align-self: flex-end;
          img {
            object-fit: fill;
            width: 100%;
            height:100%
        };
      }
        .botImg__hidden--right{
          display:none;
          width: 48%;
          img {
            object-fit: fill;
            width: 100%;
            height:100%
          }
        }
        .botImg--left {
          width: 45%;
          align-self: flex-end;
          img {
            object-fit: fill;
            width: 100%;
            height:100%
          }
        }
        .botImg--right {
          width: 48%;
          img {
            object-fit: fill;
            width: 100%;
            height:100%
          }
        }
      }
      .topContent__text__container {
        display: flex;
        flex-direction: column;
        margin-bottom:100px;

        .topContent__header--sm {
          color: #747474;
          margin-bottom: 16px;
        }

        .topContent__header--lg {
          color: #1c1d1e;
        }
        .topContent__text {
          margin-top: 25px;
          width: 93%;

          .topContent__text__content {
            line-height: 1.6;
            color: #747474;
          }
        }
      }
    }
    .topContent__container--right {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img__portrait__container {
        width: 100%;
        height: 45%;
        // margin-bottom: 50px;
        img {
          width: 100%;
          object-fit: fill;
        }
      }

      .img__landScape__container {
        width: 100%;
        height: 31%;
        img {
          width: 100%;
          object-fit: fill;
          height:100%
        }
      }
    }
  }

  .aboutUs__botContent__container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
    .botContent__textCard--left {
      width: 50%;
      padding-top: 28px;
      display:flex;
      flex-direction:column;
      align-items: center;
      .textCard__header--left {
        width: 90%;
        line-height: 1.31;
        color: #1c1d1e;
        margin-bottom: 19px;
        text-align:center;
      }
      .textCard__text--left {
        width: 75%;
        line-height: 1.75;
        color: #747474;
        text-align: center;
        .textCard__button__container{
          margin-top:10px;
            width:100%;
            display:flex;
            justify-content:center;
        }
      }
    }
    .botContent__textCard--right {
      width: 50%;
      background-color: #1c1d1e;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .textCard__header--right {
        color: #ffda00;
        text-align: center;
      }
      .textCard__text--right {
        width: 75%;
        text-align: center;
        color: #ffffff;
        line-height: 1.6;

      }
    }
  }
}
.aboutUs__midContent__container{
    width:100%;
    display:flex;
    background-color: #1c1d1e;
    justify-content: center;
    .midContent__container--left{
      padding-top:120px;
      padding-bottom:120px;
        width:30%;
        display:flex;
        flex-direction:column;

        .text__header__container{
            color:#ffda00;
        }
        .text__title__container{
            color:#ffffff;
        }
        .text__content__container{
            color:#bebebe
        }
    }

    .midContent__container--right{
        width:56%;
        display:flex;
        flex-direction:column;
        justify-content: space-around;
        align-items:center;
    }
  }

  @media screen and (max-width: 1199px) {
    #mobile-img__portrait__container{
      height:100% ;
      vertical-align:middle;
      align-items:center;
      display:flex;
    }

    #mobile-midContent__container--left{
      padding-left: 10%;
      width:35%
    }
  }
@media screen and (max-width: 779px){
  #mobile-midContent__container--left{
    padding-left:2%
  }
}
  @media screen and (max-width: 739px){
    #mobile-aboutUs__topContent__container{
      .topContent__container--right{
        display:none;
      }
      .topContent__container--left{
        width:100%;

        .text__botImg__container{
          .botImg--left{
            display:none;
          }
        
          .botImg__hidden--right {
            display:block;
          }
        }
      }
    }
    #mobile-midContent__container--left{
      padding-left:0
    }
  }

  @media screen and (max-width: 645px){
    #mobile-aboutUs__topContent__container{
      margin-top:0
    }
    #mobile-aboutUs__midContent__container{
      flex-direction: column;
      .midContent__container--left{
        width:100%;
        padding: 55px 9% 21px 9%
      }
      .midContent__container--right{
        width:100%;
        margin-bottom: 4%;
        .midContent__textCard{
          align-self:center;
          margin:1% auto
        }
      }
    }
    #mobile-aboutUs__botContent__container{
      flex-direction:column;
      .botContent__textCard--left{
        width:100%;
        margin-bottom: 4%
      }
      .botContent__textCard--right{
        width:100%;
      }
    }
  }

  @media screen and (max-width:414px) {
    .botContent__textCard--right{
      padding-top:3%;
      padding-bottom:3%
    }
   
  }