// *{
//   border:1px solid black;
// }
.ltwim__navbar__wrapper {
  width: 100vw;
  height: 70px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
  .navbar__wrapper__container {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar__header {
      height: 60%;
      width: 36%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 2;
      .nav__home__text {
        span {
          color: #1c1d1e;
          text-decoration: none;
        }
      }
      .nav__home__text:hover {
        text-decoration: none;
      }
      .nav__home__logo {
        display: none;
      }
    }

    .navbar__links__container {
      width: 28%;
      justify-content: space-between;
      display: flex;
      a {
        text-decoration: none;
        color: #1c1d1e;
        font-size: 13px;
      }
      a:hover {
        animation: scaleTransform 0.1s forwards;
      }
      a.active {
        border-bottom: solid 2px #1c1d1e;
      }
    }
    @keyframes scaleTransform {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.1);
      }
    }
    .navbar__contactUs__container {
      width: 36%;
      display: flex;
      justify-content: flex-end;
      height: 45%;
      flex-shrink: 2;

      .navbar__contactUs__links {
        width: 111px;
        border: solid 3px #1c1d1e;
        background-color: black;
        border-radius: 30px;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        a {
          text-decoration: none;
          color: white;
          vertical-align: middle;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .ltwim__navbar__wrapper {
    .navbar__wrapper__container {
      .navbar__header {
        width: 30%;
      }
      .navbar__links__container {
        width: 40%;
      }
      .navbar__contactUs__container {
        width: 30%;
      }
    }
  }
}

@media screen and (max-width: 687px) {
  #mobile-navbar__header {
    width: 11%;
    .nav__home__logo {
      display: flex;
    }
  }
  .nav__home__text {
    display: none;
  }

  #mobile-navbar__links__container {
    width: 63%;
    flex-wrap: wrap;
  }
  #mobile-navbar__contactUs__container {
    width: 18%;
    align-items: center;
    vertical-align: middle;
    .navbar__contactUs__links {
      height: 84%;
      a {
        font-size: 11px;
      }
    }
  }

  .nav__logo {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
    #mobile-navbar__contactUs__container {
      width: 16%;
      .navbar__contactUs__links {
        height: 68% ;
        a {
          font-size: 8px;
        }
      }
    }
    #mobile-navbar__links__container {
      width: 65%;
    }
}
@media screen and (max-width: 320px) {
  #mobile-navbar__links__container {
    a {
      font-size: 11px;
    }
  }
  #mobile-navbar__contactUs__container {
    .navbar__contactUs__links {
      a {
        font-size: 7px;
      }
    }
  }
}

@media screen and (max-width: 280px) {
    #mobile-navbar__links__container {
      width: 49%;
    }
    #mobile-navbar__contactUs__container {
      width: 17%;
    }
  }
