.textCard__text__header{
    margin-top:54px;
    .textCard__text__title{
        color:#1c1d1e;
    }
    .textCard__text__content{
        font-size:11px;
        color:#747474;
        line-height: 1.75;
    }
}

.textCard__text__subHeader{
    margin-top:27px;
    .textCard__text__title{
        color:#1c1d1e
    }
    .textCard__text__content{
        font-size:11px;
        color:#747474;
        line-height: 1.75;;
    }
}
