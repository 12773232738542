::-webkit-scrollbar {
  display: none;
}
.onGoingProj__container {
  h1 {
    text-align: center;
    margin-bottom: 2%;
  }
  margin-top: 5%;
}
.banner__wrapper {
  background-color: #1c1d1e;
}

.banner__logo {
  text-align: center;
  color: white;
  padding-top: 54px;
  padding-bottom: 53px;

  .landing__banner__logo {
    width: 176px;
    height: 183px;
    object-fit: contain;
  }
}

.banner__scripts {
  text-align: center;
  color: white;
  padding: 6% 1.3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .banner__scripts__title {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 8px;
    .banner__scripts__block {
      width: 0%;
      height: inherit;
      background: #ffb510;
      position: absolute;
      animation: mainBlock 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      display: flex;
    }

    .scripts__title {
      font-family: "Poppins";
      color: #fff;
      font-size: 39px;
      -webkit-animation: mainFadeIn 1s forwards;
      -o-animation: mainFadeIn 1.5 forwards;
      animation: mainFadeIn 1s forwards;
      animation-delay: 0.6s;
      opacity: 0;
      display: flex;
      align-items: baseline;
      // position: relative;

      .banner__scripts__dot {
        width: 0px;
        height: 0px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        background: #ffb510;
        -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92)
          forwards;
        animation: popIn 0.8s cubic-bezier(0.74, 0.6, 0.4, 0.92) forwards;
        animation-delay: 1s;
        margin-left: 1px;
        margin-top: -10px;
        // position: absolute;
        top: 43px;
        right: -12px;
      }
    }
  }

  .banner__scripts__address {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: -10px;

    .banner__scripts__block {
      width: 0%;
      height: inherit;
      background: #5d6d7c;
      position: absolute;
      animation: secBlock 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      animation-delay: 1s;
      display: flex;
    }

    .scripts__address {
      animation: secFadeIn 1s forwards;
      animation-delay: 1.5s;
      opacity: 0;
      font-weight: 400;
      font-family: "Lato";
      color: #ffffff;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 5px;
    }
  }
}

.aboutus__wrapper {
  background-color: white;
  background-size: cover;
  background-position-y: 24%;
  background-image: url("https://cdn.pixabay.com/photo/2016/11/24/20/30/architecture-1857175_960_720.jpg");
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.aboutus__scripts {
  padding: 70px;
  margin: 50px;
  h3 {
    animation: fade-in-left 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  p {
    animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation-delay: 0.5s;
  }
  a {
    animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation-delay: 1s;
  }
}

.aboutus__button {
  border-radius: 30px;
  height: 50px;
  width: 150px;
  border: none;
  background-color: black;
  color: white;
}

.aboutus__button:hover {
  background-color: rgb(42, 42, 42);
}

.info__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  height: 800px;
  .info__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15%;
    // margin-top: 35px
  }
  .info__text {
    color: #747474;
    text-align: center;
    width: 80%;
    line-height: 1.4;
  }
  .info__content__wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    height: 40%;
    & > div {
      // margin: 10px;
      width: 45%;
    }
  }
  .info__content__wrapper--reversed {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: center;
    height: 40%;
    & > div {
      margin: 10px;
      width: 45%;
    }
  }
  .info__content__image {
    background-color: #383232;
    box-shadow: 5px 5px 5px grey;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .info__content__image:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    box-shadow: 5px 5px 5px grey;
  }
  .info__content__text-box {
    display: flex;
    flex-direction: column;

    padding: 20px;
    align-items: center;
    justify-content: center;
    .info__content_text-bold {
      font-weight: 700;
      font-size: 23px;
      text-align: center;
      margin-bottom: 7px;
    }
    .info__content_text {
      font-size: 12p;
      text-align: center;
      color: #747474;
      line-height: 1.64;
    }
  }
}

.info__content__wrapper {
  display: grid;
}

// @for $row from 1 through $rows {
//   @for $col from 1 through $cols {
//     .demo__part-#{$row}-#{$col} .demo__part-back-inner {
//       transform: translate( -($col - 1) * ($partSize + $margins * 2), -($row - 1) * ($partSize + $margins * 2) );
//     }
//   }
// }
.projects__wrapper {
  background-color: white;
}

.projects__contents {
  margin: 50px 50px 0px 50px;
}

.projects__scripts {
  padding-bottom: 20px;
  text-align: center;
}
.projects__card__grid {
  padding: 35px;
}

.projects__card__style {
  box-shadow: 5px 5px 5px grey;
}

.projects__card__style:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
  box-shadow: 5px 5px 5px grey;
}

.projects__button {
  padding-top: 35px;
  display: flex;
  justify-content: center;
}

.projects__button__style {
  border-radius: 30px;
  height: 60px;
  width: 150px;
  border: none;
  background-color: black;
  color: white;
}

.projects__button__style:hover {
  background-color: rgb(42, 42, 42);
}

.contact__wrapper {
  background-color: #383232;
  box-shadow: 5px 5px 5px grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  padding: 40px;
  color: white;
  background-size: cover;
  background-position-y: 82%;
}

.contact__scripts {
  margin: 20px;
  text-align: center;
}

.contact__button {
  border-radius: 30px;
  height: 50px;
  width: 150px;
  border: none;
  background-color: black;
  color: white;
}

.contact__button:hover {
  background-color: rgb(42, 42, 42);
}

@media screen and (max-width: 800px) {
  .projects__card__wrapper {
    display: flex;
    justify-content: center;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.projects__carousel__container {
  width: 100%;
  margin: 0 auto;
}

.carousel__slides {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
}

.carousel__slide {
  position: relative;
  display: block;
  flex: 1 0 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 700ms ease-out;
  vertical-align: top;
  box-sizing: border-box;
  white-space: normal;

  figure {
    display: flex;
    margin: 0;
    .carousel__slide__image {
      box-shadow: 0 1.7px 1.1px -10px rgba(19, 10, 43, 0.09),
        0 4.3px 3px -10px rgba(19, 10, 43, 0.13),
        0 8.9px 6.6px -10px rgba(19, 10, 43, 0.17),
        0 11px 17px -10px rgba(19, 10, 43, 0.3);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      img {
        border-radius: 6px;
      }
    }
    .carousel__slide__figCaption {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  div {
    @include aspect-ratio(3, 2);
    width: 100%;
  }

  img {
    display: block;
    flex: 1 1 auto;
    object-fit: cover;
  }

  figcaption {
    align-self: center;
    flex: 0 0 auto;
    width: 25%;
    min-width: 150px;

    a {
      button {
        margin-top: 8%;
      }
    }
  }

  .carousel__slide__title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.5);
    display: block;
    font-size: 24px;
  }

  &.scrollable {
    overflow-y: scroll;
  }
}

.carousel__thumbnails {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  margin: 0 -10px;

  .carousel__slides + & {
    margin-top: 20px;
  }

  li {
    flex: 1 1 auto;
    max-width: calc((100% / 6) - 20px);
    transition: all 300ms ease-in-out;
    margin-left: 1.25%;
    margin-right: 1.25%;
    margin-bottom: 0.5%;
  }

  label {
    display: block;
    @include aspect-ratio(1, 1);
    margin-bottom: 0px;

    &:hover,
    &:focus {
      cursor: pointer;

      img {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
        transition: all 300ms ease-in-out;
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    top: 11px;
  }
  65% {
    width: 7px;
    height: 7px;
    top: 56px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    top: 36px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 43px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@media screen and (max-width: 1199px) {
  // .app__content__wrapper{
  //   padding-right: 14px
  // }
  .banner__scripts {
    .banner__scripts__title {
      margin-bottom: 0;
    }
  }
  #mobile-landing__page__container {
    max-width: 100%;
  }
  #mobile-banner__scripts__address {
    margin-top: 0px;
  }

  .projects__card__wrapper {
    justify-content: center;
    .projects__card__grid {
      padding: 26px;
    }
  }

  .projects__contents {
    margin: 50px 0 0 0;
  }
  .projects__scripts {
    text-align: center;
  }
  .carousel__thumbnails {
    li {
      margin-left: 1.6%;
      margin-right: 1.6%;
    }
  }
}

@media screen and (max-width: 991px) {
  #mobile-banner__scripts {
    .banner__scripts__title {
      justify-content: center;
      .scripts__title {
        font-size: 36px;
      }
    }
    .banner__scripts__address {
      justify-content: center;
      .scripts__address {
        font-size: 10px;
      }
    }
  }

  #mobile-aboutus__scripts {
    padding: 4px;
  }
  #mobile-info__wrapper {
    padding-top: 13px;
    .info__header {
      margin-bottom: 12px;
    }
  }
  #mobile-projects__wrapper {
    justify-content: space-around;
    margin-right: -7px;
    .projects__contents {
      padding-right: 0px;
      padding-left: 0px;
      margin: 32px 32px 0 32px;
      .projects__scripts {
        text-align: center;
      }
    }
  }

  .carousel__slide__figure {
    &--reversed {
      display: flex;
      flex-direction: row;
    }
    flex-wrap: wrap;
    .carousel__slide__figCaption {
      width: 100%;
      display: flex;
      flex-direction: column;
      a {
        button {
          margin-top: 3%;
          margin-left: 2%;
        }
      }
    }
  }
}

@media screen and (max-width: 1004px) {
  #mobile-banner__scripts__address {
    height: 20%;
    .banner__scripts__block {
      height: 75%;
      top: 0;
    }
  }
}
@media screen and (max-width: 863px) {
  #mobile-banner__scripts {
    .banner__scripts__title {
      .scripts__title {
        font-size: 31px;
      }
    }
  }
}
@media screen and (max-width: 803px) {
  #mobile-banner__scripts {
    .banner__scripts__title {
      .scripts__title {
        font-size: 30px;
      }
    }
  }
}
@media screen and (max-width: 733px) {
  #mobile-banner__scripts {
    .banner__scripts__title {
      .scripts__title {
        font-size: 28px;
      }
    }
  }
}
@media screen and (max-width: 690px) {
  #mobile-aboutus__wrapper {
    flex-direction: column;
    .aboutus__scripts {
      margin: 0px;
      padding: 41px;
    }
  }
  .banner__wrapper {
    flex-direction: column;
    .banner__logo {
      padding-bottom: 0px;
    }
    .banner__scripts {
      padding: 31px;
    }
  }
}
@media screen and (max-width: 540px) {
  #mobile-landing__page__container {
    padding-left: 0;
    padding-right: 0;
    .projects__wrapper {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .info__wrapper {
    height: auto;
  }
  .carousel__thumbnails {
    justify-content: center;
  }
  .botContent__textCard--right {
    padding-top: 3%;
    padding-bottom: 3%;
  }
}
@media screen and (max-width: 414px) {
  .landing__page__container {
    .info__wrapper {
      height: auto;
      .info__content__wrapper {
        flex-direction: column;
        .info__content__image {
          width: 100%;
        }
        .info__content__text-box {
          width: 100%;
        }
      }
    }
    .info__content__wrapper--reversed {
      height: auto;
      flex-direction: column;
      .info__content__image {
        width: 100%;
        margin: 0;
      }
      .info__content__text-box {
        width: 100%;
        margin: 0;
      }
    }
  }
  .carousel__thumbnails {
    justify-content: center;
  }
}

@media screen and (max-width: 375px) {
  .scripts__title {
    padding-right: 0;
  }
  #mobile-banner__scripts {
    padding: 24px;
    .banner__scripts__dot {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: 320px) {
  #mobile-banner__scripts {
    .banner__scripts__title {
      .scripts__title {
        font-size: 26px;
      }
    }
    .banner__scripts__address {
      margin-top: -6px;
    }
  }
  .projects__scripts {
    h2 {
      font-size: 1.4rem;
    }
  }

  .banner__logo {
    padding-top: 15%;
  }
}

@media screen and (max-width: 280px) {
  #mobile-banner__scripts {
    .banner__scripts__title {
      .scripts__title {
        font-size: 22px;
      }
    }
    .banner__scripts__address {
      .scripts__address {
        font-size: 9px;
      }
    }
  }
}
